<template>
  <div class="leftBotContainer">
    <div class="btnBox flex">
      <div class="leftBtn flex-1 flex">
        <div
          class="btnItem"
          :class="{ redBg: btnActive == '1' }"
          @click="handClick('1')"
        >
          {{ $t("market.buyMore") }}
        </div>
        <div
          class="btnItem"
          :class="{ greenBg: btnActive == '2' }"
          @click="handClick('2')"
        >
          {{ $t("market.buyEmpty") }}
        </div>
      </div>
      <div class="hasNumBox" @click="router.push('/marketRecord')">{{ $t("market.hasNum") }}</div>
    </div>
    <p class="tipP">{{ $t("market.tip") }}</p>
    <div class="infoBox">
      <swiper :options="swiperOptions">
        <swiper-slide
          v-for="(item, index) in secondList"
          :key="index"
          class="cur-p"
        >
          <div
            class="slideItem"
            :class="{ activeSlide: activeInfo == item.seconds }"
            @click="handChoose(item)"
          >
            <div class="sliderTop flex flex-center">
              <span>{{ item.seconds }}{{ $t("market.second") }} </span>
            </div>
            <div class="sliderBot flex flex-center">
              <span>{{ item.profit_ratio }}%</span>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="numBox">
      <el-input-number
        v-model="num"
        style="width: 100%"
        size="small"
        :step="0.1"
        :precision="2"
        :min="0"
        :max="balancePrice"
        controls-position="right"
        @change="numberChange"
        :placeholder="$t('market.numberInput')"
      ></el-input-number>
    </div>
    <div class="numTips">
      <p>
        {{ $t("market.canUse") }}USDT{{ $t("market.balance") }}：{{
          balancePrice
        }}
      </p>
      <p>
        {{ $t("market.serviceCharge") }}{{ serviceMoney }} （{{
          tabType == 1 ? info.lever_trade_fee * 100 : info.fee * 100
        }}%）
      </p>
    </div>
    <div
      class="footerBtn flex flex-center"
      @click="submit"
      :class="{ redBg: btnActive == '1', greenBg: btnActive == '2' }"
    >
      {{
        btnActive == "1" ? $t("market.buyMore") : $t("market.buyEmpty")
      }}（BTC）
    </div>
  </div>
</template>
<script setup>
import { getWallet } from "@/api/user";
import { miSubmit, setWhsubmit } from "@/api/market";
import { ref, getCurrentInstance, onMounted, watch, computed } from "vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { Message } from "element-ui";
import "swiper/css/swiper.min.css";
import { useLang } from "@/hooks/useLang";
import { useRouter } from "@/utils/vueApi";
const router = useRouter();
const { lang } = useLang();
const btnActive = ref("1");
const vm = getCurrentInstance();
const handClick = (val) => {
  btnActive.value = val;
};
const swiperOptions = ref({
  slidesPerView: 4,
  spaceBetween: 10,
});
const props = defineProps({
  secondList: {
    type: Array,
    default: () => [],
  },
  // 1-数字货币 5-贵金属 6-外汇
  tabType: {
    type: String,
    default: "",
  },
  info: {
    type: Object,
    default: () => {},
  },
});
watch(
  () => props.secondList,
  (val) => {
    activeInfo.value = val[0].seconds;
  }
);
const activeInfo = ref(null);
// 时间点击
const handChoose = (item) => {
  activeInfo.value = item.seconds;
};
const num = ref(null);
// 数量改变
const numberChange = () => {};
// 余额-手续费
const balancePrice = ref();

// 手续费
const serviceMoney = computed(() => {
  // 数字货币是数量*curMarket.lever_trade_fee  贵金属和外汇是number * curMarket.fee
  let temNum = 0;
  if (props.tabType == "1") {
    if (num.value) {
      temNum = num.value * props.info.lever_trade_fee;
    }
  } else {
    if (num.value) {
      temNum = num.value * props.info.fee;
    }
  }
  return temNum;
});
// 获取钱包数据
const getBalance = async () => {
  const {
    message: { micro_wallet },
  } = await getWallet();
  balancePrice.value = micro_wallet.usdt_totle;
};
// 提交
const submit = async () => {
  if (!num.value) {
    Message.error(vm.proxy.$t("market.numberInputPlac"));
    return false;
  }
  if (props.tabType == "1") {
    const { type, message } = await setWhsubmit({
      currency_id: '3',
      match_id: props.info.id,
      number: num.value,
      seconds: activeInfo.value,
      type: btnActive.value,
      lang: lang.value
    });
    if(type == 'ok') {
      Message.success(vm.proxy.$t('tips.success'));
      getBalance();
      num.value = null;
    } else {
      Message.error(vm.proxy.$t('tips.error'));
    }
  } else {
    const { type, message } = await miSubmit({
      number: num.value,
      pid: props.info.pid,
      seconds: activeInfo.value,
      type: btnActive.value,
      lang: lang.value
    });
    if(type == 'ok') {
      Message.success(vm.proxy.$t('tips.success'));
      getBalance();
      num.value = null;
    } else {
      Message.error(vm.proxy.$t('tips.error'));
    }
  }
};
onMounted(() => {
  getBalance();
});
onMounted(() => {});
</script>

<style lang="scss" scoped>
.leftBotContainer {
  padding: 5px;
  box-sizing: border-box;
  font-size: 14px;
  .btnBox {
    font-weight: bold;
    .leftBtn {
      .btnItem {
        width: calc(50% - 5px);
        border: none;
        font-size: 14px;
        background-color: $theme-color;
        border: 1px solid $market-list-hover;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        cursor: pointer;
        border-radius: 5px;

        &:hover {
          background-color: #cd4e65;
          color: #fff;
        }
        &:last-child {
          margin-left: 10px;
          &:hover {
            background-color: #1cad90;
          }
        }
      }
    }
    .hasNumBox {
      flex: 0.3;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
      &::before {
        position: absolute;
        content: "";
        width: 30px;
        height: 2px;
        background-color: #5670f6;
        bottom: 5px;
      }
    }
  }
  .tipP {
    margin: 5px 0;
  }
  .infoBox {
    .slideItem {
      border: 1px solid $market-list-hover;
      border-radius: 10px;
      &.activeSlide {
        color: #fff;
        .sliderTop {
          background-color: #588bf8;
        }
        .sliderBot {
          background-color: #73a0fd;
        }
      }
      .sliderTop {
        background-color: $theme-color;
        padding: 10px 0;
        font-size: 18px;
        font-weight: bold;
      }
      .sliderBot {
        background-color: $section-color;
        font-size: 14px;
        padding: 2px 0;
      }
    }
  }
  .numBox {
    width: 100%;
    margin-top: 10px;
  }
  .numTips {
    margin-top: 10px;
    font-size: 14px;
    p {
      margin: 5px 0;
    }
  }
  .footerBtn {
    width: 100%;
    cursor: pointer;
    height: 38px;
    margin-top: 15px;
    font-weight: bold;
    border-radius: 5px;
  }
}
</style>
